import { render, staticRenderFns } from "./Chem1301CoverPageFormalReport.vue?vue&type=template&id=b3554586&scoped=true&"
import script from "./Chem1301CoverPageFormalReport.vue?vue&type=script&lang=js&"
export * from "./Chem1301CoverPageFormalReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3554586",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VContainer,VRow,VSimpleTable,VTextField})
